import { render, staticRenderFns } from "./CreateShoppingListForm.vue?vue&type=template&id=2506bd8a&"
import script from "./CreateShoppingListForm.vue?vue&type=script&lang=js&"
export * from "./CreateShoppingListForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputField: require('/vercel/path0/components/forms/InputField.vue').default,Plus: require('/vercel/path0/components/icons/Plus.vue').default,Tick: require('/vercel/path0/components/icons/Tick.vue').default})
