
import { mapMutations } from 'vuex'

export default {
  name: 'CreateShoppingListForm',

  data() {
    return {
      name: '',
      visible: false,
      hasSubmitted: false,
    }
  },

  methods: {
    ...mapMutations('shopping-lists', ['addShoppingList']),

    hideForm() {
      this.name = ''
      this.visible = false
    },

    async onSubmit() {
      this.hasSubmitted = true

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      this.$axios
        .$post('/shop/shopping-lists', {
          name: this.name,
        })
        .then((res) => {
          const shoppingList = { ...res.data, products: [] }

          this.addShoppingList(shoppingList)

          this.$nuxt.$emit('shopping-list-created', shoppingList)

          this.hideForm()
        })
        .catch(() => {
          this.$toast.error(this.$t('shopping-list-create-error'))
        })
    },
  },
}
