
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    formats: {
      type: Array,
      required: true,
    },

    initialProduct: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setValue(value) {
      this.$emit('input', parseInt(value))
    },
  },
}
