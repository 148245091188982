
import { Portal } from '@linusborg/vue-simple-portal'

export default {
  components: {
    Portal,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    wide: {
      type: Boolean,
      default: false,
    },

    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    setTimeout(() => {
      this.isOpen = true
    }, 100)
  },

  methods: {
    closeModal() {
      if (this.hideCloseButton) return
      if (!this.isOpen) return

      this.$emit('closed')
    },

    onESC(event) {
      event.preventDefault()
      event.stopPropagation()

      this.closeModal()
    },
  },
}
