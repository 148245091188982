
import CreateShoppingListForm from '~/components/profile/shopping-list/CreateShoppingListForm'

export default {
  name: 'BookmarkProductModal',

  components: {
    CreateShoppingListForm,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      bookmark: {
        productFormatId: this.data.selectedFormatId,
      },
    }
  },
}
