
export default {
  props: {
    value: {
      type: null,
      required: true,
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    isSharp: {
      type: Boolean,
      default: false,
    },

    bgColor: {
      type: String,
      default: 'gray',
    },

    validationMode: {
      type: String,
      default: 'lazy',
    },

    autocomplete: {
      type: String,
      default: null,
    },

    border: {
      type: Boolean,
      default: false,
    },

    isNegative: {
      type: Boolean,
      default: false,
    },

    isCoupon: {
      type: Boolean,
      default: false,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    height: {
      type: Number,
      default: 2.875,
    },

    instructions: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      innerValue: '',
    }
  },

  computed: {
    inputHeight() {
      return {
        '--height': this.height + 'rem',
      }
    },
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },

    value(newVal) {
      this.innerValue = newVal
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },

  mounted() {
    this.shouldFocus()
  },

  methods: {
    shouldFocus() {
      if (this.autofocus) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    },

    fieldStyle(field) {
      if (!this.isNegative) {
        if (!field.valid && field.validated) {
          return 'border hover:border-blue-300 focus-visible:border-blue-300 text-gray-900'
        } else {
          return 'border hover:border-blue-300 focus-visible:border-blue-300 text-blue-500'
        }
      }
      if (this.isNegative) {
        if (!field.valid && field.validated) {
          return 'border hover:border-blue-300 focus-visible:border-blue-300 text-gray-400'
        } else {
          return 'border hover:border-blue-300 focus-visible:border-blue-300 text-white'
        }
      }
      return ''
    },
  },
}
