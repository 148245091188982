
import { mapState } from 'vuex'

export default {
  props: {
    /**
     * product id is used to preselect shopping list(s)
     * that already contain that product
     */
    productId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      toastPosition: 'bottom-right',
    }
  },

  computed: {
    ...mapState('shopping-lists', ['shoppingLists']),

    mappedShoppingLists() {
      return this.shoppingLists.map((shoppingList) => {
        return {
          ...shoppingList,
          checked: shoppingList.products.some((p) => p.id === this.productId),
        }
      })
    },
  },

  mounted() {
    this.$nuxt.$on('shopping-list-created', ($event) => {
      this.add($event.code)
    })
  },

  methods: {
    toggle(shoppingList) {
      // remove from array
      if (shoppingList.checked) {
        return this.remove(shoppingList.code)
      }

      this.add(shoppingList.code)
    },

    async add(code) {
      try {
        await this.$store.dispatch(
          'shopping-lists/attachProductToShoppingList',
          {
            code,
            productId: this.productId,
          }
        )

        this.$toast.success(this.$t('added-to-shopping-list'), {
          position: this.toastPosition,
        })
      } catch (e) {
        return this.$toast.error(this.$t('error-adding-to-shopping-list'), {
          position: this.toastPosition,
        })
      }
    },

    async remove(code) {
      try {
        await this.$store.dispatch(
          'shopping-lists/detachProductFromShoppingList',
          {
            code,
            productId: this.productId,
          }
        )

        this.$toast.info(this.$t('removed-from-shopping-list'), {
          position: this.toastPosition,
        })
      } catch (e) {
        return this.$toast.error(this.$t('error-removing-from-shopping-list'), {
          position: this.toastPosition,
        })
      }
    },
  },
}
